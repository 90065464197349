@use "sass:map";
@import "commonstyle";
$darktheme-colors: ("bgcolor": #0D0F12,
	"background-color": #0C1214,
	"bordercolor": #3B424C,
	"theme-color": #959595,
	"commonbg-color":#0d0f12,
	"popup-bg-color":#171819);
$whitetheme-colors: ("bordercolor": #CCCCCC,
	"bgcolor": #EDEDED,
	"color": #4C4F53,
	"commonbgcolor":#F7F7F7,
	"panel-color":#69737A,
	"background-color": #353D4B,
	"popup-bg-color": #F7F7F7);

#left-panel b.collapse-sign {
	display: none;
}

#ribbon ol.breadcrumb {
	font-size: 13px;
	font-weight: 600;
}

.fa-exclamation {
	color: red;
	font-size: x-large;
	padding: 0px 5px;
}

.dt-toolbar {
	display: none !important;
}

table {
	width: 100%;
	background-color: inherit !important;
	color: $primary-color;
	border-collapse: collapse;
}

.demo {
	label {
		input[type=checkbox].checkbox+span {
			&:before {
				border: 1px solid $box-border;
			}
		}

		input[type=radio].radiobox+span {
			&:before {
				border: 1px solid $box-border;
			}
		}

		&:hover {
			input[type=checkbox].checkbox+span {
				&:before {
					border-color: #E77317;
				}
			}
		}

		margin-bottom: 4 !important;
	}

	.checkbox {
		i {
			border-color: $subtext-color;
		}

		padding-left: 22px;
	}

	.radio {
		padding-left: 22px;
	}

	#demo-setting+form {
		display: none;
	}

	position: absolute;
	top: 5px;
	right: 0;
	width: 160px;
	z-index: 902;
	background: #F1DA91;

	span {
		position: absolute;
		right: 0;
		top: 0;
		display: block;
		height: 30px;
		width: 30px;
		border-radius: 5px 0 0 5px;
		background: #F1DA91;
		padding: 2px;
		text-align: center;
		line-height: 28px;
		cursor: pointer;

		i {
			font-size: 17px;
		}

		&:hover {
			i {
				color: #333 !important;
			}
		}
	}

	form {
		section {
			margin: 0 !important;
		}
	}

	.btn {
		font-size: 13px !important;
		line-height: 1.5 !important;
		border-radius: 3px !important;
		padding: 3px 7px !important;
		height: auto !important;
	}
}

.demo.activate {
	right: 0 !important;
	box-shadow: -11px 12px 23px rgba(0, 0, 0, .2);
	padding: 5px 10px 10px;

	#demo-setting+form {
		display: block;
	}

	span {
		i {
			&:before {
				content: "\f00d";
			}
		}

		left: -30px;
	}
}

.demo-liveupdate-1 {
	font-size: 12px !important;
	position: absolute !important;
	left: 33px;
	top: 15px;
	display: block;
	z-index: 2;
	font-weight: 700;
	padding: 1px 61px 3px 7px;
}

.demo-btns {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin-bottom: 7px;
	}
}

.demo-icon-font {
	font-size: 14px;
	margin-bottom: 6px;
	padding-top: 4px;
	padding-bottom: 4px;

	&:hover {
		background-color: rgba(86, 61, 124, .1);
		text-decoration: none;
	}
}

.demo-modal {
	width: auto;
	max-width: 600px;
	z-index: 1;
}

// .demo-red {
// 	color: #DF413E;
// }
// .demo-orange {
// 	color: #DAA03E;
// }
.demo-border {
	border: 1px solid rgba(0, 0, 0, .2);
}

.demo-vertical-uislider {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;

	div {
		height: 200px;
		margin: 0 0 0 10px;
		display: inline-block;
	}

	&:first-child {
		margin-left: 15px;
	}
}

.toggle-demo {
	position: absolute;
	top: 10px;
	right: -8px;
	display: block;
	width: 154px;
	height: auto;
	z-index: 99;
	opacity: .9;

	&:hover {
		opacity: 1;
	}

	.btn-group {
		width: 100px;
		float: left;
	}

	span {
		display: inline-block;
		float: left;
		margin-right: 5px;
		line-height: 21px;
		color: #757575;
	}
}

#header {
	.menu-item {
		margin: 0px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 60px;
		cursor: pointer;
	}

	.menu-item_pading {
		padding: 0 10px;
	}

	.menu-left-item {
		padding: 0 10px;
	}

	.company-title {
		font: normal normal bold 12px/19px Open Sans;
	}

	.company-name {
		font: normal normal normal 14px/19px Open Sans;
	}

	#fullscreen {
		.svg-icon {
			background : {
				repeat: no-repeat;
				position: center;
			}

			width: 60px;
			height: 45px !important;
			margin-top: -3px;
		}
	}

	.notification-svg {
		background : {
			repeat: no-repeat;
			position: center;
		}

		width: 60px !important;
		height: 45px !important;
	}

	#activity.activity-svg {
		background : {
			repeat: no-repeat;
			position: center;
		}

		width: 60px !important;
		height: 45px !important;
	}

	.collapseMenu {
		.collaspe-menu-svg {
			background : {
				repeat: no-repeat;
				position: center;
			}

			width: 40px !important;
			height: 30px !important;
		}

		.collaspe-menu-sub-svg {
			background-repeat: no-repeat;
			width: 45px !important;
			height: 45px !important;
		}
	}

	.header-btn {
		&:hover {
			border-color: $commonbase-color !important;
			background: $commonbase-color !important;
		}
	}

	.dropdown-icon-menu {
		ul {
			li {
				.btn {
					&:hover {
						border-color: $commonbase-color !important;
						background: $commonbase-color !important;
					}
				}
			}
		}
	}
}


.knobs-demo {
	div {
		display: inline-block;
		margin-left: 30px;
	}

	&:first-child {
		margin-left: 0;
	}
}


.widget-box {
	background-color: $primary-color;
	box-shadow: 10px 10px 45px 0 rgb(19 46 72 / 20%);
	border-radius: 5px;
	overflow: hidden;
	padding: 20px;

	.title-box {
		.title {
			.ed-icon {
				transform: translateY(-50%) scale(0.8);
				transform-origin: left center;
			}

			.title-lbl {
				font-size: 18px;
				line-height: 18px;
				padding-left: 35px;

				&:before {
					left: 25px;
				}
			}

			.title-lbl.no-icon {
				padding-left: 0;

				&:before {
					display: none;
				}
			}
		}
	}

	.icon-box {
		width: 70px;
		min-width: 70px;
		height: 70px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;

		.number {
			font-size: 30px;
		}

		.ed-icon {
			width: 30px;
			height: 30px;
		}
	}

	h3 {
		font-size: 24px;
		line-height: 24px;
	}

	p {
		font-size: 18px;
		line-height: 20px;
	}
}

.activity.ajax-dropdown {
	&:first-child {
		margin: 0;
		padding: 0;
	}
}

.activity {
	.ajax-notifications {
		border-bottom: none;
		border-top: none;
	}
}

.applist-box1 {
	.applist-row {
		padding: 0 15px;
		flex-wrap: wrap;

		.app-box {
			text-align: center;
			padding: 20px 0px;
			font-weight: 500;

			.module-icon {
				width: 45px;
				height: 45px;
				background-repeat: no-repeat;
				display: inline-block;
				background-size: 100%;
			}
		}
	}
}

.fixed-navigation {
	.nav-demo-btn {
		display: none !important;
	}
}

.minified {
	.nav-demo-btn {
		display: none !important;
	}
}

.smart-rtl {
	.demo {
		.margin-right-5 {
			margin-right: 0 !important;
		}
	}
}

.listFinal {
	font-size: 16px;
	padding: 5px;

	&::before {
		content: ">";
		font-size: 20px;
		padding: 5px;
	}
}

// body:not(.menu-on-top).desktop-detected {
// 	min-height: auto !important;
// 	overflow: auto;
//           }
.minified {
	#clearCache_text {
		display: none;
	}

	#help_text {
		display: none;
	}

	.userlabel {
		display: none !important;
	}

	.body-image {
		width: 40px !important;
		height: 40px !important;
		margin: -15px 0px 10px 0px;
	}
	.colDisplay {
		padding: 12px 0px !important;
	}
	#clearCache .iom_btn_cache {
		padding: 3px 13px!important;
	}
}


// .header-dropdown-list {
// 	li {
// 		.dropdown-toggle {
// 			margin-top: 6px;
// 		}
// 	}
// }


.header-dropdown-list {
	.dropdown-menu {
		li {
			a {
				border-radius: 0 !important;
				font-size: 14px;
			}
		}

		padding: 4px;
		overflow: auto;
		max-height: 200px;

		&::-webkit-scrollbar {
			width: 0px;
		}

		border-radius: 10px;
	}
}

.nav-demo-btn {
	display: none
}

body:not(.menu-on-top) .nav-demo-btn {
	display: block;
	padding: 6px 5px;
	margin: 5px 10px;
	width: auto;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	font-size: 12px;
	white-space: normal
}

.ajax-dropdown-app>:first-child {
	margin: 0 0 3px;
	padding: 0 0 9px;
}

// .page-footer {
//     position: fixed;
//     z-index: 1000;
//     border-top: transparent;
// }
.hidden-menu .page-footer {
	padding-left: 20px;
}

.dashHeadLocation {
	color: $primary-color;
	display: flex;
	list-style: none;
	padding: 0;
}

.listitems {
	font-size: 24px;
}

.main-nav {
	text-align: right;
	font: normal normal normal 16px/22px Open Sans;
	letter-spacing: 0px;
	color: $base-color;
	opacity: 1;
	padding-top: 11px;
}

.main-container {
	display: flex;

	flex : {
		direction: column;
		grow: 1;
	}

	padding : {
		left: 0;
		right: 10px;
	}
}

// .border-line {
//     background: #0C1214 0% 0% no-repeat padding-box;
//     border: 1px solid #3B424C;
//     background: #0C1214 0% 0% no-repeat padding-box;
//     border: 1px solid #3B424C;
//     opacity: 1;
// }
#content {
	background: $table-bgcolor !important;
}

.footerTextColor {
	color: #8B91A0 !important;
}

.theme-header-color {
	color: $base-color !important;
}

.theme-text-color {
	color: $base-color !important;
}

.header-menu {
	font: normal normal normal 14px/19px Open Sans;
}

.notification-body>li {
	border: 1px solid #e3e3e3 !important;
}

.flag.flag-en {
	background-position: -144px -154px;
}

#left-panel {
	position: absolute;
	top: 51px;
	z-index: 904;
	padding-top: 10px;

	nav {
		ul {
			li.active {
				>a {
					&:before {
						right: -19px;
						content: none !important;
					}
				}
			}
		}
	}
}

.font-size-14 {
	font-size: 16px !important;
}

.notification-body {
	.subject {
		color: $subtext-color !important;
	}
}


// .userAccountSvg {
// 	width: 45px !important;
// 	height: 45px !important;
// 	background-image: url("../img/iconSvg/user.png");
// }
// .tools_Svg{
// 	width: 45px !important;
// 	height: 45px !important;
// 	background-image: url("../img/iconSvg/tools.pngks");
// }
.ellipse {
    background-image: url('../img/ellipse.svg');
    position: absolute;
    left: 0px;
	top: -1px;
    width: 330px;
    height: 270px;
    background-position: center;
    background-repeat: no-repeat;

}
.top-right{
	background-image: url('../img/top_right.svg');
    position: absolute;
    right: 0px;
	top: 0px;
    width: 140px;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
}
.bottom-left{
	background-image: url('../img/bottom_left.svg');
    position: absolute;
    left: 0px;
	bottom: 0px;
    width: 210px;
    height: 110px;
    background-position: center;
    background-repeat: no-repeat;
}
.bottom-right {
	background-image: url('../img/bottom_right.svg');
    position: absolute;
    right: 0px;
	bottom: 0px;
    width: 160px;
    height: 180px;
    background-position: center;
    background-repeat: no-repeat;
}
.iombackground {
	background : {
		repeat: no-repeat;
		position: center;
	}
	// max-height: 100vh;
	// height: 100vh !important;
	font-family: 'Poppins';
	background: #fcfceb;
	/* fallback for old browsers */
	
	background-size: cover;
	background-repeat: no-repeat;
}

.onboardingbackground {
	background : {
		repeat: no-repeat;
		position: center;
	}

	// max-height: 100vh;
	height: 1000px !important;
	width: 100%;

	background: #C04848;
	/* fallback for old browsers */
	background: linear-gradient(rgb(102, 102, 102, 0.9)),
	url(/assets/img/onboardingbg.webp);
	/* Chrome 10-25, Safari 5.1-6 */
	background: url(/assets/img/onboardingbg.webp);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-size: cover;
	background-repeat: no-repeat;
}

.iombackgrounds {
	background : {
		repeat: no-repeat;
		position: center;
	}

	// max-height: 100vh;
	// height: 100vh !important;


	// background: #C04848;  /* fallback for old browsers */
	//     background: linear-gradient(rgb(102,102,102,0.9), rgb(102,102,102,0.9)), url(/assets/img/register.webp);  /* Chrome 10-25, Safari 5.1-6 */
	//     background: linear-gradient(rgb(102,102,102,0.9), rgb(102,102,102,0.9)), url(/assets/img/register.webp); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-size: cover;
	background-repeat: no-repeat;
}
.userAccountSvg {
	background : {
		repeat: no-repeat;
		position: center;
		filter: invert(25%) sepia(50%) saturate(400%) hue-rotate(40deg) brightness(85%);
		size: contain;
	}
	// filter: brightness(100) invert(1);
	width: 55px !important;
	height: 40px !important;
	background-image: url("../img/iconSvg/htmlcode.png");
}

.toolsvg {
	background : {
		repeat: no-repeat;
		position: center;
		filter: invert(25%) sepia(50%) saturate(400%) hue-rotate(40deg) brightness(85%);
		size: contain;
	}
	// filter: brightness(100) invert(1);
	width: 55px !important;
	height: 33px !important;
	margin-top: 2% !important;
	background-image: url("../img/iconSvg/cloud.png");
}

.support-svg {
	background : {
		repeat: no-repeat;
		position: center;
		filter: invert(25%) sepia(50%) saturate(400%) hue-rotate(40deg) brightness(85%);
		size: contain;
	}
	// filter: brightness(100) invert(1);
	width: 55px !important;
	margin-top: 2%;
	height: 33px !important;
	background-image: url("../img/iconSvg/customercare.png");
}

.conection-svg {
	background : {
		repeat: no-repeat;
		position: center;
	}

	width: 45px !important;
	height: 45px !important;
	background-image: url("../img/iconSvg/conections.png");
}

.data-svg {
	background : {
		repeat: no-repeat;
		position: center;
		filter: invert(25%) sepia(50%) saturate(400%) hue-rotate(40deg) brightness(85%);
		size: contain;
	}
	// filter: brightness(100) invert(1);
	width: 55px !important;
	height: 45px !important;
	background-image: url("../img/iconSvg/cloud_protector.png");
}



.terms-and-conditions-container h1.service-heading,
.privacy-policy-container h1.service-heading,
.legal-notice-container h1.service-heading {
	padding: 20px 0 0 0;
	margin-bottom: 20px;
	color: $fontcommon-color;
	/* font: 700 36px/47px "Raleway",sans-serif; */
	position: relative;
}

.terms-and-conditions-container .agb-cont ol {
	list-style-type: none;
	counter-reset: item;
	margin: 0;
	padding: 0;
}

.terms-and-conditions-container .agb-cont ol {
	list-style-type: none;
	counter-reset: item;
	margin: 0;
	padding: 0;
}

.terms-and-conditions-container .agb-cont ol li {
	display: table;
	counter-increment: item;
	margin-bottom: 0.75em;
	font-size: 17px;
	line-height: 47px;
	color: #474544;
	/* font-family: "Ubuntu"; */
	font-weight: 700;
	position: relative;
}

.darktheme.smart-style-5 .terms-and-conditions-container .agb-cont ol li {
	color: $primary-color;
}

.terms-and-conditions-container .agb-cont ol li ol {
	margin-top: 1rem;
}

.terms-and-conditions-container .agb-cont li li {
	margin: 10px 0;
	color: #717171;
	/* font-family: "Raleway",sans-serif; */
	font-size: 1.5rem;
	line-height: 24px;
	font-weight: 400;
}

.terms-and-conditions-container .agb-cont li li:before {
	content: counters(item, ".") " ";
	padding-left: 0;
}

.terms-and-conditions-container .agb-cont li:before {
	content: counters(item, ".") ". ";
	display: table-cell;
	padding-right: .6em;
	font-weight: 600;
	padding-left: 15px;
}

.terms-and-conditions-container .agb-cont ol li:after {
	content: "";
	width: 100%;
	height: 48px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.terms-and-conditions-container .agb-cont ol li li:after {
	display: none;
}

.terms-and-conditions-container .service-info-text a {
	color: #1570b8;
	font-weight: 700;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.terms-and-conditions-container .service-info-text a:hover {
	color: $fontcommon-color;
}

.terms-and-conditions-container,
.legal-notice-container {
	padding-right: 15px;
	padding-left: 18px;
	font-size: 1.5rem;
	//     width: 1949px;
	height: 100vh;
	overflow: auto;
}

.privacy-policy-container {
	/* font-family: "Raleway",sans-serif; */
	//     font-size: 1.5rem;
	line-height: 1.75rem;
	font-weight: 400;
	color: #343e47;
	display: block;
	padding: 0px 15px 0px;
	padding-right: 15px;
	padding-left: 18px;
	font-size: 1.5rem;
	height: 930px;
	overflow: auto;
}

.privacy-policy-container li {
	display: list-item;
	text-align: -webkit-match-parent;
}

.privacy-policy-container h4 {
	font-size: 17px;
	line-height: 32px;
	font-weight: 700;
	color: #474544;
	padding-left: 15px;
	padding-right: 15px;
	min-height: 48px;
	display: flex;
	align-items: center;
}

.darktheme.smart-style-5 .privacy-policy-container h4 {
	color: $primary-color;
}

.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text p {
	color: #474544;
	margin-top: 10px;
	padding-left: 15px;
	padding-right: 15px;
	line-height: 24px;
}

.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text p span,
.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text ul li span {
	line-height: 24px;
}

.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text .well {
	padding: 15px;
}

.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text .well p {
	margin: 5px 0;
	padding-left: 0;
	padding-right: 0;
}

.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text a {
	color: #1570b8;
	font-weight: 700;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text a:hover {
	color: $fontcommon-color;
}

.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text ul {
	margin-left: 15px;
	margin-right: 15px;
}

.privacy-policy-container .our-solution-services.comm-space .services-info-main .service-info-text ul li {
	position: relative;
	padding-left: 1.3rem;
	margin-top: 5px;
	color: #717171;
	line-height: 24px;
}

.legal-notice-container .service-info-text h4 {
	font-size: 17px;
	line-height: 32px;
	color: #474544;
	font-weight: 700;
	min-height: 48px;
	display: flex;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 10px;
}

.darktheme.smart-style-5 .legal-notice-container .service-info-text h4 {
	color: $primary-color;
}

.legal-notice-container .service-info-text p {
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 0 15px;
}

.legal-notice-container .service-info-text address {
	margin-bottom: 0;
	margin-left: 15px;
}

.legal-notice-container .service-info-text .well.auth-rep p {
	margin-bottom: 0;
	font-weight: 700;
}

.legal-notice-container .service-info-text .well p {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 0;
}

.scroll-content::-webkit-scrollbar {
	display: none;
}

.scroll-content {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.mobile-view-activated::-webkit-scrollbar {
	display: none;
}

.mobile-view-activated {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.MessageBoxButtonSection button {
	border-radius: 15px;
	background: transparent;
	border: 2px solid $commonbase-color !important;
	font-size: 13px;
	padding: 3px 15px;
	color: $primary-color !important;
	font-weight: 400 !important;

}



.minified nav>ul>li>a>.menu-item-parent {
	top: -1px;
	height: 41px;
}

.ui-datepicker {
	select.ui-datepicker-month {
		margin: 0 2px;
		width: 47%;
	}

	select.ui-datepicker-year {
		margin: 0 2px;
		width: 47%;
	}
}

.ui-datepicker {

	.ui-datepicker-next {
		span {
			background: none !important;
		}
	}

	.ui-datepicker-prev {
		span {
			background: none !important;
		}
	}
}

.ui-datepicker-prev {
	span {
		background-image: none !important;
	}
}

.ui-datepicker-next {
	span {
		background-image: none !important;
	}
}

.ui-datepicker-prev {
	&:before {
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		font-weight: normal;
		align-items: center;
		justify-content: center;
		background: $primary-color;
		z-index: 9;
		padding-top: 4px;
		content: "\f053";
	}
}

.ui-datepicker-next {
	&:before {
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		font-weight: normal;
		align-items: center;
		justify-content: center;
		background: $primary-color;
		z-index: 9;
		padding-top: 4px;
		content: "\f054";
	}
}

.MessageBoxMiddle .MsgTitle {
	letter-spacing: -1px;
	font-size: 24px;
	font-weight: 300;
	margin-left: -28px;
}

.editButton {
	font-size: 37px;
	text-align: center;
	font: normal normal normal 30px/41px Open Sans;
	letter-spacing: -0.3px;
	border: 2px solid $commonbase-color;
	color: #202528;
	border-radius: 19px;
	font-size: 13px;
	top: 55px;
	position: relative;
	float: right;
	width: 155px;
	height: 34px;
	right: 220px;

	&:hover {
		background: $commonbase-color !important;
		color: $secondaryFocus !important;
	}
}

.itemEditButton {
	font-size: 37px;
	text-align: center;
	font: normal normal normal 30px/20px Open Sans;
	letter-spacing: -0.3px;
	border: 2px solid $commonbase-color;
	border-radius: 19px;
	font-size: 13px;
	padding: 3px 15px;

	&:hover {
		background: $commonbase-color !important;
		border-color: $commonbase-color !important;
		box-shadow: unset !important;
		-webkit-box-shadow: unset !important;
	}

	&:active {
		background: $commonbase-color !important;
		border-color: $commonbase-color !important;
	}
}

.modalBtn {
	/* max-width: 150px; */
	/* width: 110px; */
	margin-top: 10px;
	border: 2px solid $commonbase-color;
	padding: 3px 15px;
}

button[disabled],
html input[disabled] {
	cursor: not-allowed;
}

.sub-btn {
	font-size: 14px;
	border-radius: 15px;
	background-color: transparent !important;
	padding: 3px 15px;
	margin-right: -13px;
}

.text-danger {
	color: $invalid-color !important;
	font-size: 15px;
}

@media (max-width: 979px) {
	.page-footer {
		padding: 5px 14px 0;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	.flag+span {
		max-width: 100% !important;
	}
}

@media(max-width:992px) {
	#fullscreen {
		.svg-icon {
			width: 40px !important;
		}
	}

	#header #activity.activity-svg {
		width: 40px !important;
	}

	#header .notification-svg {
		width: 40px !important;
	}

}

.minified {
	.profile-image-box {
		display: none;
	}

	#left-panel ul {
		margin-top: -28px !important;
	}
}

.smart-style-2.minified {
	#left-panel ul {
		margin-top: -68px !important;
	}
}

.dt-toolbar-footer {
	padding: 5px 0px;
}

.blacktooltip {
	background-color: #000;
	border: none !important;
}

.badge-disable-iom {
	cursor: no-drop !important;
	opacity: .5;
}
.iom_btn {
	border-radius: 15px;
	background: transparent;
	border: 2px solid $commonbase-color;
	font-size: 13px;
	padding: 3px 15px;
	line-height: 20px;
	font-weight: 400;
	
}
.iom_btn_cache{
	border-radius: 15px;
	border: 2px solid $commonbase-color;
	font-size: 13px;
	padding: 3px 15px;
	line-height: 20px;
	font-weight: 400;
}
          .g_id_signin {
	display: none !important;
          }
/*
---------------------------------------------------------------------------------------
  Dark theme style
---------------------------------------------------------------------------------------
*/

.smart-style-1 {

	// .profile-pic{
	// 	text-align: left !important;
	// 	position: absolute !important;
	// 	top: 36px;
	//           }
	body {
		background-color: $table-bgcolor !important;
	}

	#ribbon .breadcrumb li:last-child {
		color: $fontcommon-color !important;
	}

	.breadcrumb>li+li:before {
		color: $fontcommon-color;
	}

	.auth-text-color {
		color: map.get($darktheme-colors, "bgcolor") !important;
	}

	#left-panel {
		nav {
			ul {
				li {
					border-bottom: 1px solid $commonbase-color;

					&:hover {
						background: $commonbase-color !important;
						color: $fontcommon-color !important;
						// border-top: 1px solid #666666;
						// border-bottom:1px solid #3B3B3B33;
						// border:1px solid #3B3B3B33;
						border-bottom: 1px solid #3B3B3B33 !important;
					}
				}

				li.active {
					background-color: $commonbase-color !important;

					// margin-right: -1px !important;
					a {
						color: #202427 !important;
					}
				}
			}
		}
	}

	// #left-panel {
	// 	nav {
	// 		ul {
	// 			li.active {
	// 				a {
	// 					&:before {
	// 						color: $primary-color !important;
	// 					}

	// 					color: $primary-color !important;
	// 				}

	// 				color: $primary-color !important;

	// 				a {
	// 					color: $primary-color !important;
	// 				}
	// 			}

	// 			ul {
	// 				li {
	// 					a {
	// 						color: $panel-color !important;
	// 					}
	// 				}
	// 			}

	// 			li {
	// 				a {
	// 					color: $panel-color !important;
	// 				}

	// 				color: $panel-color !important;
	// 				border-bottom: 1px solid $navigation-border;
	// 			}
	// 		}
	// 	}
	// }

	#ribbon {
		.breadcrumb {
			li {
				&:last-child {
					color: $primary-color !important;
				}

				color: $panel-color;
			}
		}
	}

	.theme-header-text-color {
		color: $primary-color !important;
	}

	.theme-subtext-color {
		color: map.get($darktheme-colors, "theme-color") !important;
	}

	#content {
		background: $content-bg-color !important;
	}

	.dashabord {
		.jarviswidget {
			div {
				background-color: map.get($darktheme-colors, "background-color") !important;
				border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
			}
		}
	}

	.theme-icon {
		color: $primary-color !important;
	}

	.theme-text-color {
		color: $base-color !important;
	}

	.ajax-dropdown {
		border: 1px solid #313840 !important;
	}

	.notification-body {
		li {
			border: 1px solid #252b31 !important;
		}
	}

	.dropdown-menu {
		background-color: map.get($darktheme-colors, "background-color");
		border: 1px solid map.get($darktheme-colors, "bordercolor");

		li {
			a {
				color: $primary-color;
			}
		}
	}

	.header-dropdown-list {
		.dropdown-menu {
			li {
				a {
					&:hover {
						background-color: $table-bgcolor !important;
					}
				}
			}

			.active {
				a {
					background-color: $table-bgcolor !important;
				}
			}
		}
	}

	.applicationHead {
		height: 34px;
		background: $content-bg-color;
		color: $primary-color;
		opacity: 1;
		border: unset;
		margin-left: 5px;
		margin-right: -5px;
	}

	#fullscreen {
		.svg-icon {
			background-image: url("../img/iconSvg/Path 222.svg");
		}
	}

	#header {
		.notification-svg {
			background-image: url("../img/iconSvg/39-Notification.svg");
		}

		#activity.activity-svg {
			background-image: url("../img/iconSvg/Page-1.svg");
		}

		.collapseMenu {
			.collaspe-menu-svg {
				background-image: url("../img/iconSvg/Path 224.svg");
			}

			.collaspe-menu-sub-svg {
				background-image: url("../img/iconSvg/Group224.svg");
				// filter: brightness(0) invert(1);
			}

			&:hover {
				background-color: map.get($darktheme-colors, "bgcolor");
			}
		}

		.header-btn {
			background-color: $table-bgcolor;
		}

		.header-btn-list {
			a {
				background-color: $table-bgcolor !important;
			}
		}

		.menu-item {
			// .webComponetsBorder {
			// 	border-right: 1px solid map.get($darktheme-colors, "bgcolor");
			// }

			// &:not(:last-child) {
			// 	border-right: 1px solid map.get($darktheme-colors, "bgcolor") !important;
			// }

			&:hover {
				background-color: #666666;
			}

			// .webComponetsHoverColor {
			// 	&:hover {
			// 		background-color: map.get($darktheme-colors, "bgcolor");
			// 	}
			// }
		}

		.theme-text-color {
			color: $primary-color !important;
		}

		.menu-item.no-hover {
			&:hover {
				background-color: $table-bgcolor;
			}
		}

		.menu-left-item {
			// border-left: 1px solid map.get($darktheme-colors, "bgcolor");
			border-left: unset !important;
		}
	}

	// .theme-bg-color {
	// 	background-color: #1e1e1e !important;
	// }
	.jarviswidget-color-darken {
		header {
			background: map.get($darktheme-colors, "popup-bg-color") !important;
			border: none;
		}
	}

	.spanBoderBottom {
		border-bottom: 1px solid map.get($darktheme-colors, "bordercolor") !important;
	}

	.modalBodyBgColor {
		background-color: map.get($darktheme-colors, "popup-bg-color") !important;
	}

	.applicationHeadModal {
		background: map.get($darktheme-colors, "bgcolor") !important;
	}

	.theme-fade-color {
		color: map.get($darktheme-colors, "theme-color") !important;
	}

	.subList {
		&:not(:last-child) {
			border-bottom: 1px solid $content-bg-color !important;
		}
	}

	.border {
		border: 1px solid #666666 !important;
		border-radius: 10px;
	}

	.userProfile {
		.applicationBackGround {
			background-color: $content-bg-color !important;
			margin-right: -5px;
		}

	}

	.menuSelected {
		background-color: #666666;
	}

	.jarviswidget {
		>div {
			border: none;
		}
	}

	.border-line {
		border: 1px solid $subborder-color;
		background-color: map.get($darktheme-colors, "background-color") !important;
		opacity: 1;
	}

	nav ul li a {
		color: $primary-color !important;
		font-size: 14px !important;

		&:focus,
		&:hover {
			background: $commonbase-color !important;
		}
	}

	.model-7 .checkbox label:after {
		background: $fontcommon-color !important;
	}

	.body-images {
		border-radius: 100%;
	}

	.editButton {
		background-color: $content-bg-color;
	}

	.itemEditButton {

		background-color: $content-bg-color;
		color: $primary-color !important;

		&:hover {
			color: $hovertext-color !important;
		}
		&:active {
			color: $hovertext-color !important;
		}
	}

	.profileDeatils {
		border: 1px solid #666666;
		margin-top: -36px;
		background: $content-bg-color 0% 0% no-repeat padding-box;
		border-radius: 10px;
		// height: 605px;       
		margin-left: 24px;
	}

	.modalBtn {

		color: $primary-color !important;

	}

	.profileModalBtn {
		padding: 10px;
		// margin-left: -15px;
		background-color: transparent;
		border: 2px solid $commonbase-color !important;
		color: $primary-color !important;
		border-radius: 19px;
		font-size: 13px;

		&:hover {
			background: $commonbase-color !important;
			color: $secondaryFocus !important;
		}
	}

	.profileEditTextBox {
		background: $content-bg-color 0% 0% no-repeat padding-box !important;
	}

	// .changePasswordBtn, .editProfileBtn {
	// 	&:hover {
	// 		border-color:$commonbase-color !important;
	// 		background: $commonbase-color !important;
	// 		color: $fontcommon-color !important;
	// 	}
	// }

	.smallLang {
		display: block !important;

		.dropdown-toggle::after {
			color: $fontcommon-color !important;
		}

		.header-dropdown-list {
			.dropdown-toggle {
				.language-text {
					color: #212529 !important;
				}
			}

			.dropdown-menu {
				li {
					a {
						&:hover {
							background-color: map.get($whitetheme-colors, "bgcolor") !important;
						}
					}
				}

				.active {
					a {
						background-color: map.get($whitetheme-colors, "bgcolor") !important;
					}
				}

				background-color: $primary-color !important;
				border: 1px solid rgba(0, 0, 0, .15) !important;

				.dropdown-item {
					color: #212529 !important;
				}
			}
		}

	}

	.ui-datepicker {
		background: #202125 !important;
		border-radius: 5px !important;

		.ui-datepicker-header {
			background-color: #202125;
		}

		select.ui-datepicker-month {
			border-color: $datepicker-color;
			background: #202125;
		}

		select.ui-datepicker-year {
			border-color: $datepicker-color;
			background: #202125;
		}

		th {
			color: $primary-color !important;
		}

		td {
			.ui-state-default {
				color: $primary-color;
			}

			.ui-state-active {
				background: $tabsborder-color;
				color: $primary-color !important;
			}
		}
	}

	.ui-datepicker-header {
		a {
			color: $primary-color !important;
		}
	}

	.ui-datepicker-month {
		color: $primary-color !important;
	}

	.ui-datepicker-year {
		color: $primary-color !important;
	}

	.ui-datepicker-prev {
		&:before {
			background: #202125;
		}
	}

	.ui-datepicker-next {
		&:before {
			background: #202125;
		}
	}

	.NextconfirmLabels {
		color: $primary-color;
		border: 1px solid #666666;
	}

	.reMark {
		color: $primary-color;
		border: 1px solid #666666;
	}

	#spnFilePath {
		color: $primary-color !important;
	}

	.sub-btn {
		border: 2px solid $commonbase-color !important;
		color: $primary-color !important;
		font-weight: bold;

		&:hover {
			background: $commonbase-color !important;
			color: $secondaryFocus !important;
		}
	}

	.theme-fade-in {
		color: $primary-color;
	}

	.successDiv {
		// background-color: #171819;
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.theme-content-color {
		color: $primary-color;
	}

	.successchangepassDiv {
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.MessageBoxButtonSection button {
		color: $primary-color;
	}

	.payBody,
	.modal_header {
		color: $primary-color;
	}

	.NextconfirmLabel {
		color: $primary-color;
	}

	.devOptions {
		background-color: #171819;
		color: #fff;

		textarea {
			background-color: #171819;
		}
	}
	#header.colorChangeTest{
		.header-btn {
			background-color: transparent;
			box-shadow: none;
			&:hover{
				background-color: $table-bgcolor;
			}
		}
		.dropdown-icon-menu{
			&:hover{
				.header-btn {
					background-color: $table-bgcolor;
				}
			}
		}
		
	}
	.modal-body {
		background-color: $widget-bgcolor !important;
	}

	.modal-content {
		background-color: $widget-bgcolor !important;
		border: 1px solid $header-bgcolor;
		color: $primary-color;
		border-radius: 10px;
		// width: 900px;
	}

	.modal-footer {
		padding: 20px 0px 0px 0px;		
		border-top: none;
	}
	.btn-primary {
		background: transparent !important;
		border: 2px solid $commonbase-color;
		color: $primary-color !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;

		&:hover {
			background: $commonbase-color !important;
			color: $common-color !important;
			border: 2px solid $commonbase-color;
		}
	}
	.navFooter{
		.iom_btn {
			color: $primary-color !important;
			&:hover {
				color: $fontcommon-color !important;
			}
		}
	}
	// .logoutSection{
	// 	.iom_btn {
	// 		color: #202427 !important;
	// 		&:hover {
	// 			color: $fontcommon-color !important;
	// 		}
	// 	}
	// }
	.iom_btn {
		color: $primary-color !important;
		&:hover {
			border-color: $commonbase-color !important;
			background: $commonbase-color !important;
			color: $hovertext-color !important;
		}
	}
	/* #clearCache_text {
		&:hover {
			color: #333;
		}
	} */
	.company-label {
		color: $primary-color;
	}
	#clearCache {
		color: #fff;
		.iom_btn_cache{
			color: #333;			
			&:hover {
				color: #fff;
				background: $commonbase-color !important;
			}
		} 
	}
	.colDisplay{
		#clearCache {
			color: #fff !important;
			.iom_btn_cache{
				color: #fff !important;				
				&:hover {
					color: $hovertext-color !important;
					background: $commonbase-color !important;
				}
			} 
		}
	}
	
	.fillGreen{
		color: $fontcommon-color !important;
	}
	.MessageBoxButtonSection button:hover {
		background: $commonbase-color !important;
		color: $hovertext-color !important;
	}
}

/*
---------------------------------------------------------------------------------------
    End   Dark theme style
---------------------------------------------------------------------------------------
*/

/*
---------------------------------------------------------------------------------------
    White theme style
---------------------------------------------------------------------------------------
*/


.smart-style-2 {

	.applicationHead {
		height: 34px;
		background: $primary-color 0% 0% no-repeat padding-box;
		color: $fontcommon-color !important;
		opacity: 1;
		border: unset;
		margin-left: 5px;
		margin-right: -5px;
	}

	// #left-panel {
	// 	padding-right: 0;
	// 	padding-left: 0;
	// 	padding-bottom: 0;
	// 	padding-top: 49px;

	// 	nav {
	// 		ul {
	// 			li {
	// 				a {
	// 					padding: 12px 17px 12px 16px;

	// 					i {
	// 						font-size: 17px
	// 					}
	// 				}
	// 			}
	// 		}

	// 		ul {
	// 			li {
	// 				a {
	// 					font-size: 14px !important;
	// 					color: map.get($whitetheme-colors, "panel-color") !important;
	// 				}

	// 				color: map.get($whitetheme-colors, "panel-color") !important;
	// 			}

	// 			.active {
	// 				a {
	// 					color: map.get($whitetheme-colors, "panel-color") !important;
	// 				}
	// 			}

	// 			li.open {
	// 				a {
	// 					color: map.get($whitetheme-colors, "panel-color") !important;

	// 					b {
	// 						color: map.get($whitetheme-colors, "panel-color") !important;
	// 					}
	// 				}
	// 			}

	// 			li.active {
	// 				a {
	// 					color: map.get($whitetheme-colors, "color") !important;
	// 				}

	// 				color: map.get($whitetheme-colors, "color") !important;

	// 				a {
	// 					color: map.get($whitetheme-colors, "color") !important;

	// 					&:before {
	// 						color: map.get($whitetheme-colors, "color") !important;
	// 					}
	// 				}
	// 			}

	// 			ul {
	// 				li {
	// 					a {
	// 						color: map.get($whitetheme-colors, "panel-color") !important;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	#left-panel {
		padding-right: 0px !important;
		padding-left: 0px !important;
		padding-bottom: 0px !important;
		padding-top: 10px !important;

		nav {
			ul {
				li {
					border-bottom: 1px solid $commonbase-color;

					&:hover {
						background: $commonbase-color !important;
						color: $fontcommon-color !important;
						// border-top: 1px solid #666666;
						border: 1px solid #3B3B3B33;
						// border-bottom: none;
					}
				}

				li.active {
					background-color: $commonbase-color !important;

					a:before {
						content: unset !important;
					}
				}
			}
		}
	}

	nav {
		ul {
			li.active {
				a {
					&:before {
						color: #202427;
					}
				}
			}
		}

		ul {
			li {
				a {
					color: $primary-color !important;
					padding: 12px 17px 12px 16px !important;

					i {
						font-size: 17px !important;
						vertical-align: 0 !important;
					}
				}
			}
		}
	}

	nav ul li.active>a:before {
		content: unset !important;
	}

	.notification-body {
		.unread {
			background-color: map.get($whitetheme-colors, "bgcolor");
		}

		.from {
			color: map.get($whitetheme-colors, "color") !important;
		}

		.msg-body {
			color: $subtext-color !important;
		}

		.activityTime {
			color: $subtext-color !important;
		}

		li {
			span {
				background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			}
		}
	}

	.ajax-dropdown {
		border: 1px solid #d9d9d9 !important;
	}

	#fullscreen {
		.svg-icon {
			background-image: url("../img/iconSvg/light-theme/Path222.svg");
		}
	}

	#header {
		.notification-svg {
			background-image: url("../img/iconSvg/light-theme/39-Notification.svg");
		}

		#activity.activity-svg {
			background-image: url("../img/iconSvg/light-theme/Page-1.svg");
		}

		.collapseMenu {
			.collaspe-menu-svg {
				background-image: url("../img/iconSvg/light-theme/Path 224.svg");
			}

			.collaspe-menu-sub-svg {
				background-image: url("../img/iconSvg/Group224.svg");
			}

			&:hover {
				background-color: map.get($whitetheme-colors, "bordercolor");
			}
		}

		.header-btn {
			box-shadow: none;
		}

		.header-btn-list {
			a {
				box-shadow: none;
			}
		}

		.menu-item {
			&:not(:last-child) {
				// border-right: 1px solid map.get($whitetheme-colors, "bordercolor");
				border: unset !important;
			}

			.webComponetsBorder {
				// border-right: 1px solid map.get($whitetheme-colors, "bordercolor");
				border: none;
			}

			&:hover {
				background-color: map.get($whitetheme-colors, "bordercolor");
			}

			.webComponetsHoverColor {
				&:hover {
					background-color: map.get($whitetheme-colors, "bordercolor");
				}
			}
		}

		.menu-item.no-hover {
			&:hover {
				background-color: map.get($whitetheme-colors, "bgcolor");
			}
		}
	}

	.header-dropdown-list {
		a.dropdown-toggle {
			color: map.get($whitetheme-colors, "color") !important;
		}

		.dropdown-menu {
			li {
				a {
					&:hover {
						background-color: map.get($whitetheme-colors, "bgcolor") !important;
					}
				}
			}

			.active {
				a {
					background-color: map.get($whitetheme-colors, "bgcolor") !important;
				}
			}
		}
	}

	.theme-reverse-text-color {
		color: $secondaryFocus !important;
		color: $primary-color !important;
	}

	.theme-icon {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-text-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-header-text-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-subtext-color {
		color: $subtext-color !important;
	}

	.dashboard {
		.jarviswidget {
			div {
				background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
				border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			}
		}
	}

	.profileBackground {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	#ribbon {
		.breadcrumb {
			li {
				color: map.get($whitetheme-colors, "panel-color");

				&:last-child {
					color: map.get($whitetheme-colors, "color") !important;
				}
			}
		}
	}

	.userProfile {
		.applicationBackGround {
			background-color: $primary-color !important;
			margin-right: -5px;
		}

		.theme-text-color {
			color: #202528 !important;
			font-weight: 700;
		}
	}

	.border {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		border-radius: 10px;
	}

	.subList {
		&:not(:last-child) {
			// border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			border-bottom: 1px solid $primary-color !important;
		}
	}

	.theme-fade-color {
		color: $subtext-color !important;
	}

	.applicationHeadModal {
		background: map.get($whitetheme-colors, "background-color") !important;
	}

	.modalBodyBgColor {
		background: $primary-color 0% 0% no-repeat padding-box !important;
	}

	.spanBoderBottom {
		border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	.jarviswidget-color-darken {
		header {
			// background: map.get($whitetheme-colors, "popup-bg-color") !important;
			background: $primary-color 0% 0% no-repeat padding-box !important;
			border: none;
		}
	}

	.theme-bg-color {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.theme-header-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.footerTextColor {
		color: $primary-color !important;
	}

	.login-info {
		color: #212529 !important;
	}

	#content {
		background: $primary-color !important;
	}

	.auth-text-color {
		color: map.get($whitetheme-colors, "bordercolor") !important;
	}

	.menuIconsOption {
		img {
			filter: brightness(100) invert(1);
		}
	}

	.menuSelected {
		background-color: map.get($whitetheme-colors, "bordercolor");
	}

	// .jarviswidget {
	// 	>header {
	// 		// border: none !important;
	// 	border: 1px solid map.get($whitetheme-colors, "bordercolor");
	// 	border-bottom: transparent !important;
	// 	}
	// }
	nav ul li.active>a:before {
		content: unset !important;
	}

	.dropdown-icon-menu>ul>li .btn,
	.header-btn {
		background: none;
	}

	.body-images {
		border-radius: 100%;
		// 	box-shadow: 5px 10px 75px rgba(0, 0, 0, .4);
		// 	/* float: left; */
		// 	/* margin: -130px 5px 20px; */
		// 	position: relative;
		//     z-index: 2;
		//     max-width: none;
		//     width: 140px;
		//     height: 140px;
	}

	.profileDeatils {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		margin-top: -36px;
		background-color: $primary-color;
		border-radius: 10px;
		// height: 605px; 
		margin-left: 24px;
	}

	.theme-text-color {
		color: #202528;
	}

	.jarviswidget>div {

		border-right-color: $primary-color !important;
		border-bottom-color: $primary-color !important;
		border-left-color: $primary-color !important;

	}

	.editButton {
		background-color: $primary-color;
	}

	.itemEditButton {
		background-color: $primary-color;
		color: #202528;
		border-color: $commonbase-color !important;

		&:active {
			color: $primary-color !important;
		}
		&:hover {
			color: $hovertext-color !important;
		}
	}

	.modalBtn {

		color: $fontcommon-color !important;
	}

	.profileModalBtn {
		padding: 10px;
		// margin-left: -15px;
		background-color: $primary-color !important;
		border: 2px solid $commonbase-color !important;
		color: $fontcommon-color !important;
		border-radius: 19px;
		font-size: 13px;

		&:hover {
			background: $commonbase-color !important;
			color: $hovertext-color !important;
		}
	}

	.profileEditTextBox {
		background-color: map.get($whitetheme-colors, "popup-bg-color") !important;
	}

	.NextconfirmLabels {
		color: $common-color;
		border: 1px solid #ced4da;
	}

	.reMark {
		color: $common-color;
		border: 1px solid #ced4da;
	}

	#spnFilePath {
		color: $fontcommon-color !important;
	}

	.darkHeaderIcon,
	.lightHeaderIcon {
		color: $common-color !important;
	}

	.sub-btn {
		border: 2px solid $commonbase-color !important;
		color: $primary-color !important;
		font-weight: bold;

		&:hover {
			background: $commonbase-color !important;
			color: $primary-color !important;
		}
	}

	.theme-fade-in {
		color: $fontcommon-color;
	}

	.heaerText,
	.headerimgTxt {
		color: $secondaryFocus !important;
	}

	.successDiv {
		// background-color: #171819;
		background-color: #F7F7F7 !important;
	}

	.theme-content-color {
		color: #666666;
	}

	.successchangepassDiv {
		// background-color: #171819;
		background-color: #F7F7F7 !important;
	}

	.MessageBoxButtonSection button {
		color: $common-color !important;
	}

	.NextconfirmLabel {
		color: $widget-bgcolor;
	}

	.devOptions {
		background-color: #f5f5f5;
		color: #171819;

		textarea {
			background-color: #f5f5f5;
		}
	}
	.btn-primary {
		background: #fff !important;
		border: 2px solid $commonbase-color;
		color: #202124 !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;
	}
	.navFooter{
		.iom_btn {
			color: $light-nav-font-color !important;
			&:hover {
				color: #202427!important;
			}
		}
	}
	.iom_btn {
		color: #202427 !important;
		&:hover {
			color: $hovertext-color !important;
			background: $commonbase-color !important;
		}
	}
	// .logoutSection{
	// 	.iom_btn {
	// 		color: #202427 !important;
	// 		&:hover {
	// 			color: #202427!important;
	// 		}
	// 	}
	// }
	.company-label {
		color: $light-nav-font-color;
	}
	#clearCache {
		color: $light-nav-font-color;
		.iom_btn_cache{
			color: $light-nav-font-color;			
			&:hover {
				color: $hovertext-color !important;
				background: $commonbase-color !important;
			}
		} 
	}
	
	.fillGreen{
		color: $primary-color !important;
	}
	.MessageBoxButtonSection button:hover {
		background: $commonbase-color !important;
		color: $hovertext-color !important;
	}
}




/*
---------------------------------------------------------------------------------------
    End White theme style
---------------------------------------------------------------------------------------
*/

/* .registeContent #clearCache .iom_btn {
	color: #333 !important;
} */
#header.colorChangeTest{
	background-color: $header-color !important;
}
.cookie-text{
	font-size: 12px;
	
          }
          .show-consent {
	          display: flex;
	          display: -webkit-box;
	          display: -moz-box;
	          display: -ms-flexbox;
	          display: -webkit-flex;
          }
          .cookie-consent {
	          width: 100%;
	          flex-wrap: wrap;
	          position: fixed;
	          bottom: 0;
	          left: 0;
	          right: 0;
	          color: #d6d6d6;
	          background-color: #3c404d;
	          padding: 0.6em 1.8em;
	          z-index: 9999;
	padding: 10px 0 0 10px ;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
          }
          .consent-btn {
	          color: $fontcommon-color;
	          flex: 1 0 130px;
	          background-color: $commonbase-color;
	          border-color: transparent;
	          padding: 0.2em 0.5em;
	          font-size: 1.2em;
	          font-family: Helvetica,Calibri,Arial,sans-serif;
	          font-weight: bolder;
	          border-width: 2px;
	          border-style: solid;
	          text-align: center;
	          white-space: nowrap;
	          border-radius: 5px;
	          min-height: 20px;
	margin: 0 30px 10px 10px;
	max-width: 100px;
          }
          a{
	color: $commonbase-color;
	text-decoration: none;
	font-weight: 500;
	&:hover{
		color: $commonbase-color;
	}
}
.crossBtn {
	color: $commonbase-color;
}

.epost
	{
		.epostlogoBackGround{
			background-repeat: no-repeat;
			background-size: 100%;
			height: 140px !important;
			width: 62px !important;
			display: block;
			margin-top: 1px !important;
			margin-left: 82px !important;
		}	
	
}
.green-label{
	color: $commonbase-color !important;
}
.bg-color-red{
	background-color: $commonbase-color !important;
}
.ellipse, 
.top-right,
.bottom-left,
.bottom-right {
	filter: $filter
}
.io-market {
	.ellipse, 
	.top-right,
	.bottom-left,
	.bottom-right {
	filter: none !important;
}
}
.miniLogo-align {
	margin-top: 10px;
    display: flex;
    justify-content: center;
}
.top-right {
    margin-top: 3% !important;
    margin-right: -2% !important;
}
span.highlight {
    color: #ADBD2F !important;
}
a.login_style_txt {
    font-weight: 400 !important;
	text-decoration: underline !important;
	color: rgb(47, 134, 248) !important;
}

@media (min-width:577) and (max-width: 900px) {
    .registeContent-style {
        height: 985px !important;
    }
}
// @media (max-height: 900px) {
//     .registeContent-style {
//         height: 900px !important;
//     }
    
// }
@media (max-width:576) {
    .registeContent-style[_ngcontent-xug-c139] {
        height: 985px !important;
    }
}
@media (min-width: 1642px) {
    .iombackground {
        height: 100vh !important;
    }
}
@media (min-width: 992px) and (max-width:1641px){
    .iombackground {
        height: 1200px !important;
    }
}
@media (min-width: 820px) and (max-width:991px){
        .iombackground {
            height: 900px !important;
        }
}
@media (min-width:660px) and (max-width: 819px) {
    .iombackground {
        height: 1200px !important;
    }
}
@media (min-width:420px) and (max-width: 659px) {
    .iombackground {
        height: 1300px !important;
    }
}
@media (max-width: 419px) {
    .iombackground {
        height: 1600px !important;
    }
}
@media (max-width: 993px) {
	.ellipse {
		background-size: contain !important;
		height: 140px !important;
		background-position: left !important;
	}
	.top-right {
		background-size: contain;
		height: 100px;
	}
	.bottom-left {
		background-size: contain;
		height: 50px;
		margin-bottom: 3%;
	}
	.bottom-right {
		background-size: contain;
		height: 150px;
		background-position: right;
	}	
	.register-text {
        margin-top: 0% !important;
        font-size: 35px !important;
        line-height: 40px !important;
    }	
	.auth_sec_styl_algn {
		height: 120px !important;
	}
}
@media (min-width:993px) and (max-width: 1350px) {
.ellipse {
    background-size: contain;
    height: 172px;
    background-position: left;
}
}

